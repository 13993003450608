// Taigrbot.js
import React from 'react';
import TabsComponent from '../components/TabsComponent';
import '../css/Taigrbot.css';

const Taigrbot = () => {
  return (
    <div class="container">
      <header className="header">
      </header>
      <div class="container">
        <h1 class="taigrboth1">taigrbot</h1>
        <p>taigrbot aims to be capable discord utility bot which offers many features such as moderation commands, XP system, Rolemenus,Security features and many more</p>
      </div>
      <TabsComponent />
    </div>
  );
};

export default Taigrbot;
