// Home.js
import React from 'react';
import TodoList from '../components/Todolist';
import '../css/Home.css'; // Import the CSS specific to Home component
import '../css/Container.css';

function Home() {
  return (
    <div className="container">
      <h2 className="home-title">Welcome to my todolist creator</h2>
        <TodoList />
    </div>
  );
}

export default Home;
