import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../css/Navbar.css';
import '../css/Dropdown.css';

function Navbar() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <button className="mobile-menu-icon" onClick={() => setIsMobile(!isMobile)}>
          {isMobile ? <i className="fas fa-times"></i> : <i className="fas fa-bars"></i>}
        </button>
        <ul className={isMobile ? "nav-links-mobile open" : "nav-links-mobile"}>
          <li>
            <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link>
          </li>
          <li>
            <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>whoami</Link>
          </li>
          <li className="dropdown">
            <span className={location.pathname.startsWith('/service') ? 'active' : ''}>Products</span>
            <ul className="dropdown-menu">
              <li>
                <Link to="/taigrbot" className={location.pathname === '/taigrbot' ? 'active' : ''}>taigrbot - discord</Link>
              </li>
              <li>
                <Link to="/taigrtab" className={location.pathname === '/taigrtab' ? 'active' : ''}>taigrtab - minecraft</Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <span className={location.pathname.startsWith('/Tools') ? 'active' : ''}>Tools</span>
            <ul className="dropdown-menu">
              <li>
                <Link to="/cmaker" className={location.pathname === '/cmaker' ? 'active' : ''}>cvmaker</Link>
              </li>
              <li>
                <Link to="/laskin" className={location.pathname === '/laskin' ? 'active' : ''}>Calculator</Link>
              </li>
              <li>
                <Link to="/list" className={location.pathname === '/list' ? 'active' : ''}>TodoList</Link>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="nav-links">
          <li>
            <Link to="/" className={location.pathname === '/' ? 'active' : ''}>Home</Link>
          </li>
          <li>
            <Link to="/about" className={location.pathname === '/about' ? 'active' : ''}>whoami</Link>
          </li>
          <li className="dropdown">
            <span className={location.pathname.startsWith('/service') ? 'active' : ''}>Products</span>
            <ul className="dropdown-menu">
            <li>
                <Link to="/taigrbot" className={location.pathname === '/taigrbot' ? 'active' : ''}>taigrbot - discord</Link>
              </li>
              <li>
                <Link to="/taigrtab" className={location.pathname === '/taigrtab' ? 'active' : ''}>taigrtab - minecraft</Link>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <span className={location.pathname.startsWith('/Tools') ? 'active' : ''}>Tools</span>
            <ul className="dropdown-menu">
              <li>
                <Link to="/cmaker" className={location.pathname === '/cmaker' ? 'active' : ''}>cvmaker</Link>
              </li>
              <li>
                <Link to="/laskin" className={location.pathname === '/laskin' ? 'active' : ''}>Calculator</Link>
              </li>
              <li>
                <Link to="/list" className={location.pathname === '/list' ? 'active' : ''}>TodoList</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;