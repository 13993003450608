// Laskin.js
import React from 'react';
import Calculator from '../components/Calculator';
import '../css/Home.css'; // Import the CSS specific to Home component
import '../css/Container.css';

function Laskin() {
  return (
    <div className="container">
      <h2 className="home-title">Welcome to my caculator</h2>
        <Calculator />     
    </div>
  );
}

export default Laskin;
