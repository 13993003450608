// Home.js
import React from 'react';
import Changelog from '../components/Changelog';
import '../css/Home.css'; // Import the CSS specific to Home component
import '../css/Container.css';
import '../components/Changelog';

function Home() {
  return (
    <div className="container">
      <h2 className="home-title">Welcome to My Developer Website</h2>
      <p className="home-description">
        This site is dedicated to development-related purposes only.<br />
        In the <strong>"whoami"</strong> tab, you will find information about me.<br />
        In the <strong>"Products"</strong> section, you will find my projects such as minecraft plugin and discordbot.<br />
        In the <strong>"Tools"</strong> section, has some simple webtools.<br />
        <br />
        Also btw if you dont know "whoami" is linux command (also works on windows) who tells which user are you atm.
      </p>
      <main>
        <Changelog />   
      </main>
      
    </div>
  );
}

export default Home;
