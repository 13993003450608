// Footer.js
import React from 'react';

function Footer() {
  return (
    <footer className="footer">
      &copy; 2024 Tiiker1. All Rights Reserved.
    </footer>
  );
}

export default Footer;