// Header.js
import React from 'react';
import '../css/Header.css';

function Header() {
  return (
    <div className="header">
      <h1 className="animated-title">tiiker1.dev</h1>
    </div>
  );
}

export default Header;
