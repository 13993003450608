import React, { useState } from 'react';
import '../css/TabsComponent.css';
import '../css/Supporttab.css';

const TabsComponent = () => {
  const [activeTab, setActiveTab] = useState('Setup-Instructions');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <div className="tabs-container">
      <header className="tabs-header">
        <nav className="tabs-nav">
          <button
            className={`tab-link ${activeTab === 'London' ? 'active' : ''}`}
            onClick={() => handleTabClick('London')}
          >
            Moderation
          </button>
          <button
            className={`tab-link ${activeTab === 'RoleMenu' ? 'active' : ''}`}
            onClick={() => handleTabClick('RoleMenu')}
          >
            RoleMenu
          </button>
          <button
            className={`tab-link ${activeTab === 'XP-System' ? 'active' : ''}`}
            onClick={() => handleTabClick('XP-System')}
          >
            XP-System
          </button>
          <button
            className={`tab-link ${activeTab === 'Setup-Instructions' ? 'active' : ''}`}
            onClick={() => handleTabClick('Setup-Instructions')}
            id="defaultOpen"
          >
            Setup Instructions
          </button>
          <button
            className={`tab-link ${activeTab === 'Configuration' ? 'active' : ''}`}
            onClick={() => handleTabClick('Configuration')}
          >
            Configuration
          </button>
          <button
            className={`tab-link ${activeTab === 'Support' ? 'active' : ''}`}
            onClick={() => handleTabClick('Support')}
          >
            Support
          </button>
        </nav>
      </header>
      
      <section className="tab-content">
        <div className={`tab-pane ${activeTab === 'London' ? 'active' : ''}`}>
          <h3>Moderation Features</h3>
          <p>Keep your server safe with moderation commands.<br></br>
              Please do note that every moderation command needs administrator permission unless configured from discord otherwise.<br></br>
              <code>/mute</code> which mutes person.<br></br>
              <code>/unmute</code> which unmutes person.<br></br>
            </p>
        </div>
        <div className={`tab-pane ${activeTab === 'RoleMenu' ? 'active' : ''}`}>
          <h3>RoleMenu</h3>
          <p>
              Please do note that rolemenu commands needs manage roles permission (using rolemenu does not need permissions) unless configured from discord otherwise.<br></br>
              <code>/rolemenu</code> Spawns rolemenu<br></br>
              <code>/addrole</code> Adds Custom role to role menu.<br></br>
              <code>/removerole</code> Removes Custom role from role menu.<br></br>
            </p>
        </div>
        <div className={`tab-pane ${activeTab === 'XP-System' ? 'active' : ''}`}>
          <h3>XP-System</h3>
          <p>Reward active users with an engaging XP and leveling system.<br></br>
              <code>/leaderboard</code> shows leaderboard<br></br>
              <code>/xp_on/off</code> Turns xp system on or off.<br></br>
              <code>/xp_check</code> Checks user xp.<br></br>
            </p>
        </div>
        <div className={`tab-pane ${activeTab === 'Setup-Instructions' ? 'active' : ''}`}>
          <h2>Setup Instructions</h2>
          <ol className="setup-instructions-list">
            <li>
              <span>Invite the bot to your Discord server using the <a href="#">invite link</a></span>
            </li>
            <li>
              <span>Assign the necessary permissions to the bot.</span>
            </li>
            <li>
              <span>Configure the bot using the provided commands or dashboard.</span>
            </li>
            <li>
              <span>Encourage users to engage with the bot to earn XP and rewards.</span>
            </li>
          </ol>
        </div>
        <div className={`tab-pane ${activeTab === 'Configuration' ? 'active' : ''}`}>
            <h2>Configuration</h2>
            <ol>
              <li>lets start with setting up xp message channel with <code>/setxp</code></li>
              <li>After that we set channel where bug reports and player reports goes with <code>/setreports</code></li>
              <li>if needed configure command permissions from discord server settings</li>
            </ol>
        </div>
        <div className={`tab-pane ${activeTab === 'Support' ? 'active' : ''}`}>
          <h2 className="support-header">Support</h2>
          <p className="support-intro">
            If you encounter any issues with the bot or need assistance, please use the following resources to get help:
          </p>
          <ul className="support-links">
            <li className="support-item">
              <a 
                href="https://github.com/Tiiker1/taigrfull/issues" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="link-item support-link"
              >
                Report an Issue
              </a>
              <p className="description">
                Submit bugs or feature requests on our GitHub issues page.
              </p>
            </li>
            <li className="support-item">
              <a 
                href="mailto:tkr1.cloud@gmail.com" 
                className="link-item support-link"
              >
                Email Support
              </a>
              <p className="description">
                For urgent issues or hosted bot problems, contact us via email.
              </p>
            </li>
          </ul>
        </div>



      </section>
    </div>
  );
};

export default TabsComponent;
