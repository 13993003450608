// About.js
import React from 'react';
import '../css/About.css';
import '../css/Container.css';

function About() {
  return (
    <div className="container">
      <header className="header2">
        <h2>whoami</h2>
      </header>

      <section className="introduction-section">
        <h3>Introduction</h3>
        <p>
          Hello! I'm Arttu Mäkelä / Tiiker1, a passionate developer with experience in web development, 
          Discord bot creation, and more. I love creating solutions that make people's lives easier 
          and more enjoyable.
        </p>
      </section>

      <section className="skills-section">
        <h3>Skills</h3>
        <ul className="skills-list">
          <li>JavaScript / React.js</li>
          <li>Node.js</li>
          <li>Discord.py</li>
          <li>HTML / CSS</li>
          <li>Python</li>
          <li>Git / GitHub</li>
        </ul>
      </section>

      <section className="projects-section">
        <h3>Projects</h3>
        <ul className="projects-list">
          <li>
            <strong>taigrbot:</strong> feature-rich discord bot for server management and fun activities.
          </li>
          <li>
            <strong>TaigrTab:</strong> taigrtab is customizable tab plugin for minecraft with simple configuration.
          </li>
        </ul>
      </section>

      <section className="contact-section">
        <h3>Contact</h3>
        <p>If you would like to get in touch, feel free to reach out via the following channels:</p>
        <ul className="contact-list">
          <li><a href="mailto:tkr1.cloud@gmail.com">tkr1.cloud@gmail.com</a></li>
          <li><a href="https://github.com/Tiiker1" target="_blank" rel="noopener noreferrer">GitHub</a></li>
          <li><a href="https://www.linkedin.com/in/tiiker1" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
        </ul>
      </section>
    </div>
  );
}

export default About;