// Cmaker.js
import React from 'react';
import CVMaker from '../components/CVMaker';
import '../css/Home.css'; // Import the CSS specific to Home component
import '../css/Container.css';

function Cmaker() {
  return (
    <div className="container">
      <h2 className="home-title">Welcome to my cv maker</h2>
      <div class="container">
        <CVMaker />
      </div>
    </div>
  );
}

export default Cmaker;