// TaigrTab.js
import React from 'react';
import '../css/TaigrTab.css';
import '../css/Container.css';

function TaigrTab() {
  return (
    <div className="container">
      <h1 className="title">TaigrTab Minecraft Plugin</h1>

      <section className="overview section">
        <h2>Overview</h2>
        <p>
          TaigrTab is a powerful Minecraft plugin that allows server admins to customize the in-game tab list.
          It's designed to enhance gameplay experience with easy configuration and customizability.
        </p>
      </section>

      <section className="features section">
        <h2>Features</h2>
        <ul>
          <li>Easy to configure</li>
          <li>Customizable</li>
          <li>Supports 1.21 Spigot</li>
          <li>Lightweight and efficient</li>
          <li>Open-source and actively maintained</li>
        </ul>
      </section>

      <section className="installation section">
        <h2>Installation</h2>
        <ol>
          <li>Download the latest version of TaigrTab from the <a href="https://github.com/Tiiker1/taigrtab/releases/tag/release" target="_blank" rel="noopener noreferrer">Github Releases Page</a></li>
          <li>Place the downloaded .jar file into your server's plugins folder.</li>
          <li>Restart your Minecraft server.</li>
          <li>Configure the plugin using the provided configuration files in taigrtab/config.yml</li>
          <li>Enjoy the enhanced tab list in your Minecraft server!</li>
        </ol>
      </section>

      <section className="contact section">
        <h2>Contact</h2>
        <p>If you have any questions or need support, feel free to reach out:</p>
        <ul>
          <li>Issues or bugs: <a href="https://github.com/Tiiker1/taigrtab/issues" target="_blank" rel="noopener noreferrer">Report an issue</a></li>
          <li>Email: <a href="mailto:tkr1.cloud@gmail.com">tkr1.cloud@gmail.com</a></li>
        </ul>
      </section>
    </div>
  );
}

export default TaigrTab;
