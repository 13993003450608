// components/Changelog.js
import React from 'react';
import changelogData from '../data/changelog.json'; // Adjust path as needed

function Changelog() {
  return (
    <div>
      <h2>Changelog</h2>
      <ul>
        {changelogData.map((entry, index) => (
          <li key={index}>
            <strong>{entry.version}</strong>: {entry.description}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Changelog;