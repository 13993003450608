import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import '../css/Tools/cv.css';

const CVMaker = () => {
  const [profile, setProfile] = useState({
    name: '',
    title: '',
    picture: null,
    education: [],
    skills: [],
    others: '',
  });

  const cvRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => cvRef.current,
  });

  const handleDownloadPdf = () => {
    html2canvas(cvRef.current, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 295; // A4 height in mm
      const imgHeight = canvas.height * imgWidth / canvas.width;
      let heightLeft = imgHeight;

      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position -= pageHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      pdf.save('cv.pdf');
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile({ ...profile, picture: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  const addEducation = () => {
    setProfile({ ...profile, education: [...profile.education, { institution: '', degree: '', year: '' }] });
  };

  const handleEducationChange = (index, e) => {
    const { name, value } = e.target;
    const education = [...profile.education];
    education[index][name] = value;
    setProfile({ ...profile, education });
  };

  const deleteEducation = (index) => {
    const education = profile.education.filter((_, i) => i !== index);
    setProfile({ ...profile, education });
  };

  const addSkill = () => {
    setProfile({ ...profile, skills: [...profile.skills, ''] });
  };

  const handleSkillChange = (index, e) => {
    const skills = [...profile.skills];
    skills[index] = e.target.value;
    setProfile({ ...profile, skills });
  };

  const deleteSkill = (index) => {
    const skills = profile.skills.filter((_, i) => i !== index);
    setProfile({ ...profile, skills });
  };

  return (
    <div className="cv-maker-container">
      <div className="form-container">
        <h2>CV Maker</h2>
        <input
          type="text"
          name="name"
          placeholder="Your Name"
          value={profile.name}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="title"
          placeholder="Professional Title"
          value={profile.title}
          onChange={handleInputChange}
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleFileChange}
        />
        <h3>Education</h3>
        {profile.education.map((edu, index) => (
          <div key={index} className="education-item">
            <input
              type="text"
              name="institution"
              placeholder="Institution"
              value={edu.institution}
              onChange={(e) => handleEducationChange(index, e)}
            />
            <input
              type="text"
              name="degree"
              placeholder="Degree"
              value={edu.degree}
              onChange={(e) => handleEducationChange(index, e)}
            />
            <input
              type="text"
              name="year"
              placeholder="Year"
              value={edu.year}
              onChange={(e) => handleEducationChange(index, e)}
            />
            <button onClick={() => deleteEducation(index)} className="delete-btn">Delete</button>
          </div>
        ))}
        <button onClick={addEducation}>Add Education</button>
        <h3>Skills</h3>
        {profile.skills.map((skill, index) => (
          <div key={index} className="skill-item">
            <input
              type="text"
              placeholder="Skill"
              value={skill}
              onChange={(e) => handleSkillChange(index, e)}
            />
            <button onClick={() => deleteSkill(index)} className="delete-btn">Delete</button>
          </div>
        ))}
        <button onClick={addSkill}>Add Skill</button>
        <h3>Others</h3>
        <textarea
          name="others"
          placeholder="Other Information"
          value={profile.others}
          onChange={handleInputChange}
        />
        <div className="cv-buttons">
          <button onClick={handlePrint}>Print CV</button>
          <button onClick={handleDownloadPdf}>Download as PDF</button>
        </div>
      </div>
      <div className="cv-preview" ref={cvRef}>
        <div className="cv-header">
          {profile.picture && <img src={profile.picture} alt="Profile" className="cv-picture" />}
          <h1>{profile.name}</h1>
          <p>{profile.title}</p>
        </div>
        <div className="cv-section">
          <h2>Education</h2>
          <ul>
            {profile.education.map((edu, index) => (
              <li key={index}>
                {edu.institution} - {edu.degree} ({edu.year})
              </li>
            ))}
          </ul>
        </div>
        <div className="cv-section">
          <h2>Skills</h2>
          <ul>
            {profile.skills.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
        <div className="cv-section">
          <h2>Others</h2>
          <p>{profile.others}</p>
        </div>
      </div>
    </div>
  );
};

export default CVMaker;
