// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Cmaker from './pages/Cmaker';
import Laskin from './pages/Laskin';
import List from './pages/List';
import Taigrbot from './pages/Taigrbot';
import TaigrTab from './pages/Taigrtab';
import './css/App.css';
import './css/Header.css';
import './css/Footer.css';

function App() {
  return (
    <Router>
      <div>
        <Header />
        <Navbar />
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/taigrbot" element={<Taigrbot />} />
            <Route path="/taigrtab" element={<TaigrTab />} />
            <Route path="/cmaker" element={<Cmaker />} />
            <Route path="/laskin" element={<Laskin />} />
            <Route path="/list" element={<List />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
